import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import TeamMember from './TeamMember'
import TeamDetail from './TeamDetail'
import styles from './TeamList.module.scss'
import ProjectFilter from '../projects/ProjectFilter'
import {BASE_FONT_SIZE, MQ_PHONE, MQ_TABLET, TEAM_ITEMS_PER_ROW} from '../../Config'

const getItemsPerRow = (width) => {
    let num = TEAM_ITEMS_PER_ROW[MQ_PHONE]

    Object.keys(TEAM_ITEMS_PER_ROW).every(key => {
            const hit = width >= parseInt(key, 10)
            if (hit) num = TEAM_ITEMS_PER_ROW[key]
            return hit
        }
    )

    return num
}

class TeamList extends PureComponent {

    constructor(props) {
        super(props)
        this.onCloseClick = this.onCloseClick.bind(this)
        this.onMemberClick = this.onMemberClick.bind(this)
        this.onFilterChange = this.onFilterChange.bind(this)

        this.state = {
            filteredProjects: [],
            selectedMember: null,
            isTablet: false,
        }
    }

    onMemberClick(id) {
        let isTablet = false
        if (id) {
            isTablet = window.matchMedia(`(min-width: ${MQ_TABLET / BASE_FONT_SIZE}em)`).matches
        }
        this.setState(state => ({selectedMember: state.selectedMember === id ? null : id, isTablet}))
    }

    onCloseClick(event) {
        event.preventDefault()
        this.setState(() => ({selectedMember: null}))
    }

    onFilterChange(selected) {
        this.setState(() => ({filteredProjects: selected, selectedMember: null}))
    }

    render() {
        const {members, projects} = this.props
        const {filteredProjects, selectedMember} = this.state
        const filteredMembers = filteredProjects.length === 0
            ? members
            : members.filter(member => {
                const projectSlug = member.node.data.project ? member.node.data.project.document[0].slugs[0] : ''
                return filteredProjects.includes(projectSlug)
            })
        const detailMember = selectedMember ? filteredMembers.find(member => member.node.id === selectedMember) : null
        const index = selectedMember ? filteredMembers.findIndex(member => member.node.id === selectedMember) : null
        const itemsPerRow = getItemsPerRow(typeof window !== 'undefined' ? window.innerWidth : 1920)
        const row = index !== null ? (Math.floor(index / itemsPerRow) + 1) : null
        const position = row !== null ? (Math.min(row * itemsPerRow, filteredMembers.length) - 1) : null

        const visibleProjects = members.map(member => member.node.data.project ? member.node.data.project.document[0].slugs[0] : [])
        const items = []

        filteredMembers.forEach((member, index) => {
            const {id, data: {portrait_1, firstname, lastname}} = member.node

            items.push(
                <div key={id} className={styles.Item}>
                    <TeamMember
                        id={id}
                        name={`${firstname.text} ${lastname.text}`}
                        image={portrait_1}
                        isSelected={id === selectedMember}
                        onClick={this.onMemberClick}/>
                </div>,
            )

            if (selectedMember && position === index) {
                const {id, data: {portrait_2, firstname, lastname, job, about, project}} = detailMember.node

                items.push(
                    <TeamDetail
                        key={`${id}-detail`}
                        image={portrait_2}
                        firstname={firstname}
                        lastname={lastname}
                        job={job}
                        about={about}
                        project={project}
                        onCloseClick={this.onCloseClick}/>,
                )
            }
        })

        return (
            <div className={styles.Main}>
                <div className={styles.Filter}>
                    <ProjectFilter
                        onChange={this.onFilterChange}
                        visibleProjects={visibleProjects}
                        projects={projects}
                        isMoreThanOneFilterAllowed={false}/>
                </div>
                <div className={styles.List}>
                    {items}
                </div>
            </div>
        )
    }
}

TeamList.propTypes = {
    members: PropTypes.array.isRequired,
}

export default TeamList
