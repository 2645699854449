import React from 'react'
import {graphql} from 'gatsby'
import {Redirect} from '@reach/router'
import shuffle from 'shuffle-array'
import Layout from '../components/layout/Layout'
import TeamList from '../components/team/TeamList'
import Headline from '../components/headline/Headline'
import RandomImages from '../components/randomImages/RandomImages'
import Helmet from 'react-helmet'

const Page = ({data: {team, members, projects}, pageContext}) => {

    if (!team) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>
    if (!members) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>

    shuffle(members.edges)

    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >
            <Helmet>
                <title>{`Aquilas — ${team.data.headline.text}`}</title>
            </Helmet>

            <Headline headline={team.data.headline}/>
            <TeamList members={members.edges} projects={projects}/>
            <RandomImages images={team.data.random_images}/>
        </Layout>
    )
}

export const query = graphql`
query teamQuery($lang: String!) {
  team: prismicTeam(lang: {eq: $lang}) {
    data {
      headline {
        text
      }
      random_images {
        image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
  members: allPrismicPerson(filter: {lang: {eq: $lang}}) {
    edges {
      node {
        id
        data {
          firstname {
            text
          }
          lastname {
            text
          }
          job
          project {
            document {
              slugs
              data {
                title {
                  text
                }
              }
            }
          }
          about {
            html
          }
          portrait_1 {
            dimensions {
              width
              height
            }
            alt
            url
          }
          portrait_2 {
            dimensions {
              width
              height
            }
            alt
            url
          }
        }
      }
    }
  }
  projects: allPrismicProject(filter: {lang: {eq: $lang}}) {
    edges {
      node {
        slugs
        data {
          title {
            text
          }
        }
      }
    }
  }
}
`

export default Page