import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import styles from './ProjectFilter.module.scss'
import cx from 'classnames'
import Close from '../../svg/close'

class ProjectFilter extends PureComponent {

    constructor(props) {
        super(props)
        this.onResetClick = this.onResetClick.bind(this)
        this.toggle = this.toggle.bind(this)

        const {visibleProjects, initialFilter} = props
        const selected = (initialFilter && visibleProjects.includes(initialFilter)) ? [initialFilter] : []

        this.state = {
            isOpen: false,
            selected
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selected !== this.state.selected) {
            this.props.onChange(this.state.selected)
        }
    }

    toggle() {
        this.setState(() => ({isOpen: !this.state.isOpen}))
    }

    onClick(id, event) {
        event.preventDefault()
        let selected
        if (this.props.isMoreThanOneFilterAllowed) {
            selected = this.state.selected.includes(id) ? this.state.selected.filter(item => item !== id) : [...this.state.selected, id]
        } else {
            selected = this.state.selected.includes(id) ? [] : [id]
        }
        this.setState(() => ({selected}))
    }

    onResetClick(event) {
        event.preventDefault()
        this.setState(() => ({selected: []}))
    }

    render() {
        const {selected, isOpen} = this.state
        const {visibleProjects, projects} = this.props
        const displayedProjects = visibleProjects.length
            ? projects.edges.filter(project => visibleProjects.includes(project.node.slugs[0]))
            : projects.edges.map(project => project)

        return (
            <div className={styles.Main}>
                <button onClick={this.toggle} className={cx(styles.Open, {[styles.IsVisible]: !isOpen})}>
                    Show Filter
                </button>

                <div className={cx(styles.Inner, {[styles.IsVisible]: isOpen})}>
                    <button className={styles.Close} onClick={this.toggle}><Close/></button>
                    <div className={styles.ItemContainer}>
                        <button
                            className={cx(styles.Item, {[styles.IsActive]: selected.length === 0})}
                            onClick={this.onResetClick}>
                            Show all
                        </button>
                        <div className={cx(styles.LineThrough, {[styles.IsActive]: selected.length === 0})}/>
                    </div>

                    {
                        displayedProjects.map(({node: {slugs, data}}) => (
                            <div
                                key={slugs[0]}
                                className={styles.ItemContainer}>
                                <button
                                    key={slugs[0]}
                                    className={cx(styles.Item, {[styles.IsActive]: selected.includes(slugs[0])})}
                                    onClick={this.onClick.bind(this, slugs[0])}>
                                    {data.title.text}
                                </button>
                                <div className={cx(styles.LineThrough, {[styles.IsActive]: selected.includes(slugs[0])})}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default ProjectFilter


ProjectFilter.propTypes = {
    onChange: PropTypes.func.isRequired,
    projects: PropTypes.object.isRequired,
    visibleProjects: PropTypes.array,
    isMoreThanOneFilterAllowed: PropTypes.bool,
    initialFilter: PropTypes.string,
}

ProjectFilter.defaultProps = {
    visibleProjects: [],
    isMoreThanOneFilterAllowed: true,
    initialFilter: '',
}