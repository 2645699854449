import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import FitPic from '../fitPic/FitPic'
import styles from './TeamDetail.module.scss'
import Close from '../../svg/close'
import Text from '../text/Text'
import {BASE_FONT_SIZE, CURSORS, MQ_TABLET} from '../../Config'
import {AppConsumer} from '../../AppProvider'

class TeamDetail extends PureComponent {

    componentDidMount() {
        this.node.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
        })
    }

    render() {
        const {image, firstname, lastname, job, about, project, onCloseClick} = this.props
        const sizes = (image && image.url) ? `(min-width: ${MQ_TABLET / BASE_FONT_SIZE}em) 31.25vw, 100vw` : null

        return (
            <AppConsumer>
                {({showCursor, hideCursor}) => (
                    <div className={styles.Main} onClick={onCloseClick}
                         ref={node => this.node = node}
                         onMouseEnter={() => showCursor(CURSORS.CLOSE)}
                         onMouseLeave={() => hideCursor()}
                    >
                        <div className={styles.Wrapper}>
                            {(image && image.url) &&
                            <div className={styles.Image}>
                                <FitPic src={image.url}
                                        width={image.dimensions ? image.dimensions.width : 0}
                                        height={image.dimensions ? image.dimensions.height : 0}
                                        srcSet={[320, 640, 800, 1024, 1440]}
                                        sizes={sizes}
                                />
                            </div>
                            }
                            <button className={styles.Close} onClick={onCloseClick}><Close/></button>
                        </div>
                        <div className={styles.Content}>
                            <h4 className={styles.Name}>{firstname.text}<br/>{lastname.text}</h4>
                            {job && <div className={styles.Job}>{job}</div>}
                            {project && <div className={styles.Project}>{project.document[0].data.title.text}</div>}
                            {about && <div className={styles.Text}><Text text={about}/></div>}
                        </div>
                    </div>
                )}
            </AppConsumer>
        )
    }
}

TeamDetail.propTypes = {
    firstname: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }),
    lastname: PropTypes.shape({
        text: PropTypes.string.isRequired,
    }),
    about: PropTypes.shape({
        html: PropTypes.string,
    }),
    project: PropTypes.shape({
        document: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.shape({
                    text: PropTypes.string.isRequired,
                }),
            }),
        ),
    }),
    onCloseClick: PropTypes.func.isRequired,
    job: PropTypes.string,
    image: PropTypes.shape({
        url: PropTypes.string,
        dimension: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number,
        }),
    }),
}

export default TeamDetail
