import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import IsInView from '../IsInView'
import FitPic from '../fitPic/FitPic'
import styles from './TeamMember.module.scss'
import {BASE_FONT_SIZE, CURSORS, MQ_TABLET} from '../../Config'
import {AppConsumer} from '../../AppProvider'


class TeamMember extends PureComponent {
    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
    }

    onClick(event) {
        event.preventDefault()
        const {id, onClick} = this.props
        onClick(id)
    }

    render() {
        const {image, name, isSelected} = this.props
        const sizes = (image && image.url) ? `(min-width: ${MQ_TABLET / BASE_FONT_SIZE}em) 12.5vw, 50vw` : null

        return (
            <AppConsumer>
                {({showCursor, hideCursor}) => (
                    <article
                        onClick={this.onClick}
                        className={styles.Main}
                        onMouseEnter={() => showCursor(isSelected ? CURSORS.CLOSE : CURSORS.PLUS)}
                        onMouseLeave={() => hideCursor()}
                    >
                        <div className={styles.Img}>
                            {(image && image.url) &&
                            <IsInView>
                                <FitPic
                                    src={image.url}
                                    width={image.dimensions ? image.dimensions.width : 0}
                                    height={image.dimensions ? image.dimensions.height : 0}
                                    altText={image.alt}
                                    srcSet={[320, 640, 1024]}
                                    defaultWidth={320}
                                    sizes={sizes}
                                    ratio="21:29"
                                />
                            </IsInView>
                            }
                        </div>
                        <h4 className={styles.Title}>{name}</h4>
                    </article>
                )
                }
            </AppConsumer>
        )
    }
}


TeamMember
    .propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    image: PropTypes.object,
    isSelected: PropTypes.bool.isRequired,
}

export default TeamMember
